<template>
  <div class="flexCol alignItems">
    <div class="content">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>订单管理</el-breadcrumb-item>
        <el-breadcrumb-item>订单管理</el-breadcrumb-item>
        <el-breadcrumb-item>订单设置</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="content_li">
        <el-form ref="ruleForm" :model="form" label-width="150px">
          <el-form-item label="提交订单超过" prop="orderOver">
            <div class="flexRow">
              <el-input v-model="form.orderOver"></el-input>
              <span class="textLeft">分钟，用户未付款时，自动关闭订单</span>
            </div>
          </el-form-item>
          <el-form-item label="平台发货超过" prop="takeDelivery">
            <el-input v-model="form.takeDelivery"></el-input>
            <span class="textLeft">天，用户未确认收货时，自动显示收货</span>
          </el-form-item>
          <el-form-item label="订单完成超过" prop="orderEnd" v-if="false">
            <el-input v-model="form.orderEnd"></el-input>
            <span class="textLeft">天，自动结束交易</span>
          </el-form-item>
          <el-form-item label="订单完成超过" prop="comment">
            <el-input v-model="form.comment"></el-input>
            <span class="textLeft">天，用户未评价时，自动五星好评</span>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" :loading="btnLoading" @click="sumbit">提 交</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    const validatorSort = (rule, value, callback) => {
      if (value === 0) {
        callback(new Error("请输入排序"));
      } else {
        callback();
      }
    }
    return {
      search: {
        reason: "",
      },
      type: "",
      tableList: [],
      dialogVisible: false,
      tableLoading: false,
      btnLoading: false,
      formDataReady: true,
      dialogImgUrl: "",
      upData: {
        reason: "",
        remarks: "",
        sort: 0,
        status: 0,
      },
      form: {
        orderOver: 0,
        takeDelivery: 0,
        orderEnd: 0,
        comment: 0,
      },
      rules: {
        orderOver: [{required: true, message: "请输入", trigger: "blur"}],
        takeDelivery: [{required: true, message: "请输入", trigger: "blur"}],
        orderEnd: [{required: true, message: "请输入", trigger: "blur"}],
        comment: [{required: true, message: "请输入", trigger: "blur"}],
        // reason: [{required: true, message: "请输入取消原因", trigger: "blur"}],
        // sort: [{required: true, validator: validatorSort, trigger: "blur"}],
        // remarks: [{required: true, message: "请输入取消备注", trigger: "blur"}],
        // status: [{required: true, message: "请选择使用状态", trigger: "blur"}],
      },
      oderSetUpd: false,
      total: 0,
      page: 1,
      pageSize: 10
    };
  },
  mounted() {
    this.getMenuBtn();
    this.getData();
  },
  methods: {
    getData() {
      let _this = this;
      _this.tableLoading = true;

      const requestData = {
        page: this.page,
        pageSize: this.pageSize,
      }

      _this.$api
          .getOrderSet(requestData)
          .then(res => {
            if (res.data.code == 200) {
              const list = res.data.data;
              if (list.length === 0) {
                _this.form = {
                  orderOver: 0,
                  takeDelivery: 0,
                  orderEnd: 0,
                  comment: 0,
                }
              } else {
                _this.form = list[0];
              }
              _this.tableLoading = false;
            }
          }).catch(() => {
        _this.tableLoading = false;
      });
    },
    // 获取按钮权限
    getMenuBtn() {
      let _this = this;
      const list = JSON.parse(localStorage.getItem("jurisdictionList"));

      list.forEach((v) => {
        if (v == "oderSet:upd") {
          _this.oderSetUpd = true;
        }
      })
    },
    // 初始化表单
    handleInitForm() {
      let _this = this;
      _this.formDataReady = false;
      _this.$nextTick(() => {
        _this.formDataReady = true;
      });
    },
    sumbit() {
      let _this = this;
      _this.btnLoading = true;
      _this.$refs["ruleForm"].validate(valid => {
        if (valid) {
          _this.$api.editOrderSet(_this.form).then(res => {
            if (res.data.code == 200) {
              _this.$message.success("修改成功");
              _this.btnLoading = false;
              _this.getData();
              _this.handleClose();
            }
          }).catch(() => {
            _this.btnLoading = false;
          });
        } else {
          _this.btnLoading = false;
        }
      });
    },
  }
};
</script>
<style scoped lang="less">
.content {
  height: 80%;
}

.content_li {
  padding: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

/deep/ .el-form-item {
  width: 100%;
}

/deep/ .el-form {
  width: 50%;
}

/deep/ .el-form-item__content {
  width: 100%;
}

/deep/ .el-input {
  width: 20%;
}

.textLeft {
  margin-left: 10px;
}
</style>
